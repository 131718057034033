import { validateNull } from '@/utils/validate';
import { setStore, getStore } from '@/utils/store'
import store from "@/store";
import axios from "axios";
import { Toast } from 'vant'
import { LANG } from '@/configs/global'

export function loadConfig(callBack) {
    console.log(process.env)
    let path = "";
    let lang = store.getters.lang == LANG.zh_CN || store.getters.lang == LANG.zh_TW ? LANG.zh_CN : LANG.en_US
    if (store.getters.gameId == 1032) { // 龙城
        path = process.env.BASE_URL + `/data/question/QuestionConfig_${store.getters.gameId}_${lang}.json`
    } else {
        path = `/question/QuestionConfig_${1021}_${lang}.json`
    }
    console.log("---"+path)
    axios.get(path).then(function (response) {
        callBack(response.data.QuestionConfig)
        Toast.clear();
    })
}

export function saveRobotChatHistory(value) {
    let historyList = getStore({ name: 'robotChatHistory' }) || [];
    if (typeof(historyList) == 'string') {
        historyList = JSON.parse(historyList)
    }
    if (!validateNull(value)) {
        historyList.push(value);
        setStore({ name: 'robotChatHistory', content: JSON.stringify(historyList), type:'session' })
    }
}

export function getRobotChatHistory() {
    let historyList = getStore({ name: 'robotChatHistory' }) || [];
    if (typeof(searchHistoryList) == 'string') {
        historyList = JSON.parse(historyList)
    }
    return historyList
}

export function saveSearchHistory(value) {
    let searchHistoryList = getStore({ name: 'searchHistory' }) || [];
    if (typeof(searchHistoryList) == 'string') {
        searchHistoryList = JSON.parse(searchHistoryList)
    }
    if (!validateNull(value)) {
        var appendFlag = true
        searchHistoryList.forEach(function(currentValue) {
            if (currentValue === value) {
                appendFlag = false
                return
            }
        })
        // 判断-添加
        if (appendFlag === true) {
            // 长度判断
            if (searchHistoryList.length >= 5) {
                searchHistoryList.unshift(value)
                searchHistoryList.pop()
            } else {
                searchHistoryList.unshift(value)
            }
            setStore({ name: 'searchHistory', content: JSON.stringify(searchHistoryList) })
        }
    }
}

export function searchQuestions(questionsCfg, search_val) {
    let searchList = [];
    if (questionsCfg && !validateNull(search_val)) {
        for (const key in questionsCfg) {
            for (const id in questionsCfg[key]) {
                let item = questionsCfg[key][id];
                if (item.question.indexOf(search_val) > -1)  {
                    let copy = Object.assign({}, item)
                    copy.showQues = copy.question.replace(search_val, `<font color="#ff0000">${search_val}</font>`)
                    searchList.push(copy)
                }
            }
        }
    } else {
        let searchHistoryList = getStore({ name: 'searchHistory' }) || [];
        if (typeof(searchHistoryList) == 'string') {
            searchHistoryList = JSON.parse(searchHistoryList)
        }
        searchHistoryList.forEach(function(currentValue) {
            if (searchList.length < 6) {
                searchList.push({
                    "type":-1,
                    "question":currentValue,
                    "showQues":currentValue
                });
            }
        })
    }
    return searchList;
}

export function randomQuestions(questionsCfg) {

    let random = (arr, count) => {
        var shuffled = arr.slice(0), i = arr.length, min = i - count, temp, index;
        while (i-- > min) {
            index = Math.floor((i + 1) * Math.random());
            temp = shuffled[index];
            shuffled[index] = shuffled[i];
            shuffled[i] = temp;
        }
        return shuffled.slice(min);
    }

    let searchList = [];
    if (questionsCfg) {
        for (const key in questionsCfg) {
            for (const id in questionsCfg[key]) {
                let item = questionsCfg[key][id];
                searchList.push(item)
            }
        }
    }
    return random(searchList, 5);
}